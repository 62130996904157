import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-03";
import ClientFacts from "@containers/language-translation/client-facts/layout-02";
import Results from "@containers/language-translation/client-facts";
import OurSolution from "@containers/language-translation/our-solution";

const CaseStudyMncMedia = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo
        title="Case study - MNC Media"
        description="MNC Group operates four free-to-air television networks -RCTI, MNCTV, GTV, and iNews - as well as 19 channels broadcast on paid television under MNC Channels division. Its animation, 'kiko' has been distributed to 63 countries, and highlights the group's capability in developing IP characters with international standards that are accepted globally."
      />
      <Header
        data={{
          ...globalContent["header"],
          ...globalContent["menu"],
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader layout={3} data={content["mnc-header-section"]} />
        <ClientFacts data={content["mnc-client-challenges"]} />
        <OurSolution layout={4} data={content["mnc-solutions"]} />
        <Results data={content["mnc-results"]} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query caseStudyMondelezPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "use-cases" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

CaseStudyMncMedia.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default CaseStudyMncMedia;
